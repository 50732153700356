<template>
    <RouterView></RouterView>
</template>

<script setup lang="ts">
import { RouterView } from "vue-router";
import { useSessionsStore } from "@/stores/sessions";
import { onMounted } from "vue";
const sessionStore = useSessionsStore();
onMounted(() => {
    if (sessionStore.session) {
        sessionStore.refresh();
    }
});
</script>
